import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/user'
class UserService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    accessOptions(){
        return http.get(`${URL}/access/options`)
    }
}

export default new UserService()